import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from './SiteMetadata'
import PropTypes from "prop-types"
import { withPrefix } from 'gatsby'

function SEO({ uniqueDescription, lang, meta, link, uniqueTitle }) {
    const { title, description, author, siteUrl } = useSiteMetadata()

    const metaDescription = uniqueDescription || description

  return (
    <Helmet defaultTitle={title} titleTemplate={`%s | ${uniqueTitle}`}
    htmlAttributes={{
        lang,
      }}
      title={uniqueTitle}
      titleTemplate={`%s | ${title}`}
      meta={[ //for little icon
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: author,
        },
        {
          property: `theme-color`,
          content: `#871F78`,
        },
        {
          property: `og:title`,
          content: uniqueTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:img`,
          content: `${withPrefix('/')}img/og-image.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: uniqueTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: `apple-touch-icon`,
          sizes: `180x180`,
          href: `${withPrefix('/')}img/apple-touch-icon.png`
        },
        {
          rel: `icon`,
          type: `image/png`, 
          sizes: `32x32`,
          href: `${withPrefix('/')}img/favicon-32x32.png`
        },
        {
          rel: `icon`,
          type: `image/png`,
          sizes: `16x16`,
          href: `${withPrefix('/')}img/favicon-16x16.png`
        },
        {
          rel: `mask-icon`,
          href: `${withPrefix('/')}img/safari-pinned-tab.svg`,
          color: `#ff4400`,
        }
      ].concat(link)}
       script={[
        { src: 'https://identity.netlify.com/v1/netlify-identity-widget.js'},
      ]} 
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  link:[],
  uniqueDescription: ``,
}

SEO.propTypes = {
  uniqueDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object), 
  uniqueTitle: PropTypes.string.isRequired,
}
export default SEO